/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import * as HERBUI from "react-bootstrap";
import "../loginandlandingpage.css";
import { BiUser } from "react-icons/bi";
import * as Yup from 'yup';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BUDGET_PLANNING_UI_URL, BUDGET_UI_URL, DCM_UI_URL, ESR_UI_URL, ESS_UI_URL, EXPENDITURE_UI_URL, PAYROLL_UI_URL, LOGIN_AUTHENTICATION_URL, LOGIN_DOMAIN, USER_VERIFICATION, WORKS_UI_URL, HRMS_UI_URL, AUTHENTICATION_URL, USER_VERIFICATION_OTP, APGLI_UI_URL } from "../Api/APIURLS";
import { useDispatch } from "react-redux";
import { CommonAjaxGetRequestWithOutParamsWithOutAuthorization, CommonAjaxPostLoginRequest } from '../Api/CommonAjaxUtils'
// import { AiOutlineMobile } from "react-icons/ai";
// import assignLocation from "../components/Pdf/AssignLocation.pdf";
// import mobileNumberUpdation from "../components/Pdf/MobileNumberUpdationThroughDDOLogin.pdf";
var createHost = require('cross-domain-storage/host');
var createGuest = require('cross-domain-storage/guest');
const LoginPageAuthentication = () => {
    var storageHost = null;
    var mainAppguest = null;
    const [errorMsg, setErrorMsg] = useState('')
    const [otpStatus, setOtpStatus] = useState('')
    const [showFields, setShowFields] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const intialValues = { username: '', password: '', otp: "", name: "", mobileNo: "", email: "", uid: "", trn: "", OTpValidation: '' }
    const [buttonType, setButtonType] = useState("")
    const [disableUsername, setDisableUserName] = useState(false)
    const [msg, setMsg] = useState("")
    const [passwordValidation, setPasswordValidation] = useState(false)
    const validationSchema = Yup.object({
        username: Yup.string().required('Required'),
    })
    const otpPasswordValidation = Yup.object({
        otp: Yup.string().required("Required"),
        password: Yup.string().required("Required")
    })
    const UserPasswordValidation = Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().required("Required")
    })

    useEffect(() => {
        storageHost = createHost([
            {
                origin: LOGIN_DOMAIN,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: APGLI_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: EXPENDITURE_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: BUDGET_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: PAYROLL_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: HRMS_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: BUDGET_PLANNING_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: ESS_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: ESR_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },

            {
                origin: DCM_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: WORKS_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
        ]);
    }, [])

    const formik = useFormik({
        initialValues: intialValues,
        validationSchema: buttonType === "SignIn" ? (passwordValidation === false ? UserPasswordValidation : otpPasswordValidation) : validationSchema,
        onSubmit: (values) => {
            if (buttonType === "go") {
                let params = { "userId": values.username }
                // CommonAjaxGetRequestWithOutParamsWithOutAuthorization(USER_VERIFICATION + values.username).then((res) => {
                CommonAjaxPostLoginRequest(USER_VERIFICATION_OTP, params).then((res) => {
                    if (res.SCODE === "01") {
                        setOtpStatus('sent')
                        setShowFields(true)
                        setShowErrorMsg(false)
                        setDisableUserName(true)
                        setMsg(res.SDESC)
                        formik.setFieldValue("uid", res.uid)
                        formik.setFieldValue("trn", res.trn)
                        formik.setFieldValue("OTpValidation", res.optValidation)
                    }
                    else if (res.SCODE === "02") {
                        setOtpStatus('')
                        setShowFields(false)
                        setShowErrorMsg(true)
                        setErrorMsg(res.SDESC)
                        setDisableUserName(false)
                    }
                })

            }
            else if (buttonType === "SignIn") {
                localStorage.removeItem("token");
                localStorage.removeItem("serviceList");
                localStorage.clear();
                localStorage.setItem('userId', values.username)
                let params = {}
                if (String(values.OTpValidation) === "0") {
                    values.username = values.username.trim()
                    values.password = values.password.trim()
                    params = { username: values.username, password: values.password }
                    CommonAjaxPostLoginRequest(AUTHENTICATION_URL, values, dispatch).then((response) => {
                        if (response !== "" && response !== null && response !== undefined) {
                            localStorage.setItem("token", JSON.stringify(response.access_token));
                            localStorage.setItem("refresh_token", JSON.stringify(response.refresh_token));
                            localStorage.setItem('userData', JSON.stringify(response))
                            localStorage.setItem('userName', JSON.stringify(values.username))
                            mainAppguest = createGuest(LOGIN_DOMAIN);
                            let displayName = ""
                            const base64Url = response?.access_token.split('.')[1];
                            if (base64Url !== undefined) {
                                const base64 = base64Url.replace('-', '+').replace('_', '/');
                                displayName = JSON.parse(window.atob(base64)).name
                                localStorage.setItem('displayName', JSON.stringify(displayName))
                            }
                            mainAppguest.set('token', JSON.stringify(response.access_token), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('displayName', JSON.stringify(displayName), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('userData', JSON.stringify(response), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('refresh_token', JSON.stringify(response.refresh_token), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('userId', JSON.stringify(values.username), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            history.push("/dashboard");
                        }
                        else {
                            dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
                            setShowErrorMsg(true);
                            setErrorMsg("Invalid Username or Password")
                            formik.setFieldValue('username', '');
                            formik.setFieldValue('password', '');
                            formik.setFieldValue('otp', '');
                            setDisableUserName(false)
                            setShowFields(false)
                            setOtpStatus('go')
                        }
                    });
                }
                else if (String(values.OTpValidation) === "1") {
                    params = { "uid": values.uid, "otp": values.otp, "trn": values.trn, "userId": values.username.trim(), "password": values.password.trim() }
                    CommonAjaxPostLoginRequest(LOGIN_AUTHENTICATION_URL, params, dispatch).then((response) => {
                        if (response.SCODE === "01") {
                            // if (response !== "" && response !== null && response !== undefined) {
                            localStorage.setItem("token", JSON.stringify(response.token.access_token));
                            localStorage.setItem("refresh_token", JSON.stringify(response.token.refresh_token));
                            localStorage.setItem('userData', JSON.stringify(response.token))
                            localStorage.setItem('userName', JSON.stringify(values.username))
                            mainAppguest = createGuest(LOGIN_DOMAIN);
                            let displayName = ""
                            const base64Url = response?.token.access_token.split('.')[1];
                            if (base64Url !== undefined) {
                                const base64 = base64Url.replace('-', '+').replace('_', '/');
                                displayName = JSON.parse(window.atob(base64)).name
                                localStorage.setItem('displayName', JSON.stringify(displayName))

                            }
                            mainAppguest.set('token', JSON.stringify(response.token.access_token), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('displayName', JSON.stringify(displayName), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('userData', JSON.stringify(response.token), function (error, data) {
                                // foo is now set to 'bar'
                            });
                            mainAppguest.set('refresh_token', JSON.stringify(response.token.refresh_token), function (error, data) {
                                // foo is now set to 'bar'  
                            });
                            mainAppguest.set('userId', JSON.stringify(values.username), function (error, data) {
                                // foo is now set to 'bar'  
                            });

                            history.push("/dashboard");
                        }
                        else if (response.SCODE === "02") {
                            setErrorMsg(response.SDESC)
                            dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
                            setShowErrorMsg(true);
                            formik.setFieldValue('username', '');
                            formik.setFieldValue('password', '');
                            formik.setFieldValue('otp', '');
                            setDisableUserName(false)
                            setShowFields(false)
                            setOtpStatus('go')
                        }
                    }).catch(() => setTimeout(() => { }, 1500));;
                }

            }
        }
    });
    useEffect(() => {
        if (String(formik.values.OTpValidation) === "0") {
            setPasswordValidation(false)
        }
        else if (String(formik.values.OTpValidation) === "1") {
            setPasswordValidation(true)
        }
    }, [formik.values.OTpValidation])
    function knowYourChallan() {
        history.push('/knowYourCfmsId')
    }
    function forgotPassword() {
        history.push("/forgotPassword");
    }
    function gos() {
        history.push("/gos");
    }
    function newAlerts() {
        history.push("/budgetRelatedPdfDetails");
    }
    function usermanuals() {
        history.push("/usermanuals")
    }
    return (
        <>
            <FormikProvider value={formik}>
                <HERBUI.Navbar collapseOnSelect expand="lg" className="HERB-Top-Nav" variant="dark">
                    <HERBUI.Container>
                        <HERBUI.Navbar.Brand href="/login">
                            <img
                                src="img/herb.svg"
                                width="250"
                                height="45"
                                className="d-inline-block align-top"
                                alt="HERB"
                            />
                        </HERBUI.Navbar.Brand>
                        <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
                        <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
                            <HERBUI.Nav className="me-auto"></HERBUI.Nav>
                            <HERBUI.Nav>

                                {/* <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                  <b  >Know Your CFMS ID</b>
                </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                  <b  >GOs, Circulars, Memos</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => newAlerts()} className="nav_link1">
                  <b>Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>

                </HERBUI.Nav.Link> */}
                            </HERBUI.Nav>
                        </HERBUI.Navbar.Collapse>
                    </HERBUI.Container>
                </HERBUI.Navbar>
                <HERBUI.Navbar collapseOnSelect expand="lg" variant="dark">
                    <HERBUI.Container style={{ marginLeft: '0px' }}>
                        {/* <HERBUI.Navbar.Brand href="/login">
              <img
                src="img/herb.svg"
                width="250"
                height="45"
                className="d-inline-block align-top"
                alt="HERB"
              />
            </HERBUI.Navbar.Brand> */}
                        {/* <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
            <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
              <HERBUI.Nav className="me-auto"></HERBUI.Nav>
              <HERBUI.Nav>

                <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                  <b  >Know Your CFMS ID</b>
                </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <HERBUI.Nav.Link className="nav_link1" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} >
                  <b>HERB Mobile App</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => newAlerts()} className="nav_link1">
                  <b>AP Budget 2023-24 Mobile App<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => usermanuals()} className="nav_link1">
                  <b>User Manuals<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                  <b  >GOs, Circulars, Memos</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link href={'https://apfinance.gov.in/Budget2324/'} className="nav_link1">
                  <b>AP Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
              </HERBUI.Nav>
            </HERBUI.Navbar.Collapse> */}
                    </HERBUI.Container>
                </HERBUI.Navbar>
                <HERBUI.Row style={{ marginBottom: '50px' }}>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="  " >
                        <img src="img/corner.svg" className="corner" alt="dots" />
                        <div className="main_form">
                            <HERBUI.Container>
                                <HERBUI.Row>
                                </HERBUI.Row>
                            </HERBUI.Container>
                            <form className="form_adjust" style={{ marginTop: '25%' }} onSubmit={formik.handleSubmit}>
                                <div className="inner-herbpage-service-title-sub-np">
                                    <h1 style={{ fontSize: '22px' }}> Login </h1>
                                </div>
                                <HERBUI.InputGroup className="mb-3">
                                    <HERBUI.InputGroup.Text id="basic-addon1" className="group_txt">
                                        <BiUser className="font_size" />
                                    </HERBUI.InputGroup.Text>
                                    <Field name="username" placeholder="User ID" type="text" aria-label="User ID" aria-describedby="basic-addon1"
                                        className="form-control" readOnly={disableUsername} /> &nbsp;&nbsp;
                                    {showFields === false && <button type="submit" className="btn btn-success" onClick={() => setButtonType("go")}>Go</button>}
                                    <ErrorMessage name="username" className="text-error" component="div" />
                                </HERBUI.InputGroup>
                                {showFields &&
                                    <>
                                        {
                                            String(formik.values.OTpValidation) === "1" &&
                                            <div className='alert alert-info'>
                                                <span className='mb-1'>{msg}</span>
                                                &nbsp;&nbsp;
                                            </div>
                                        }
                                        {(otpStatus === 'sent' && String(formik.values.OTpValidation) === "1") &&
                                            <HERBUI.InputGroup className="mb-3">
                                                <Field placeholder="Enter OTP" name="otp" type="text" aria-label="OTP" maxLength={6}
                                                    aria-describedby="basic-addon1" className="form-control" autoComplete="off"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('otp', e.target.value)
                                                    }}
                                                />
                                                <ErrorMessage className="text-error" name="otp" component={"div"} />
                                            </HERBUI.InputGroup>
                                        }
                                        {otpStatus === 'sent' &&
                                            <HERBUI.InputGroup className="mb-3" >
                                                {/* <HERBUI.InputGroup.Text id="basic-addon1" className="group_txt">
                                                    <BiUser className="font_size" />
                                                </HERBUI.InputGroup.Text> */}
                                                <Field placeholder="Enter Password" name="password" type="password" aria-label="Password" aria-describedby="basic-addon1"
                                                    className="form-control" autoComplete="off"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('password', e.target.value)
                                                    }}
                                                />
                                                <ErrorMessage className="text-error" name="password" component={"div"} />
                                            </HERBUI.InputGroup>
                                        }
                                    </>
                                }
                                {otpStatus === 'sent' &&
                                    <div className="d-grid gap-2">
                                        <HERBUI.Button variant="primary" type="submit" className="btn_primary" onClick={() => setButtonType("SignIn")}>
                                            Sign in
                                        </HERBUI.Button>
                                        <a className="text-end forgot mb-4" style={{ zIndex: "9994" }} onClick={() => forgotPassword()}>Forgot Password ?</a>
                                    </div>
                                }
                                {showErrorMsg &&
                                    <div className="row">
                                        <div className="col-12  col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <div className="  alert alert-danger alert-dismissible fade show" role="alert">
                                                <strong>{errorMsg}</strong>
                                                <button type="button" onClick={() => setShowErrorMsg(false)} className="btn btn-close" data-dismiss="alert"></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                        <br />
                        {/* <HERBUI.Row>
                            <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mx-5>
                                <a className="text-light" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} target="_blank" rel="noreferrer">
                                    <div className="text-center  mx-5 mb-2 bg-primary p-3"> <AiOutlineMobile className="landing-icon-for-demo text-light" />
                                        Download Employee Mobile APP (HERB)
                                    </div>
                                </a>
                            </HERBUI.Col>
                        </HERBUI.Row>
                        <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
                            <b>APFRS MOBILE APP :  </b><br />
                            <span className='mb-1'>
                                <ol className='mt-2'>
                                    <li><small>  <a href={assignLocation} target="_blank" rel="noreferrer">User Manual for DDO's to Assign Locations</a> </small></li>
                                    <li><small> <a href={mobileNumberUpdation} target="_blank" rel="noreferrer">User Manual for DDO's to Employee Mobile Number Updation</a> </small></li>
                                </ol>
                            </span>

                        </div>
                        <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
                            <b>Note:  </b><br />
                            <span className='mb-1'>
                                <ol className='mt-2'>
                                    <li><small> Monthly regular salary bill submissions (including Outsourced in APCOS portal) are allowed from the 16th to the 25th as per schedule.</small></li>
                                    <li><small>Bill Submission is not permitted after 25th.</small></li>
                                </ol>
                            </span>
                        </div> */}
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
                </HERBUI.Row>

                <HERBUI.Container>
                    <HERBUI.Row>
                        <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center', marginTop: '50px' }} >
                            <HERBUI.Navbar className="border text-center">
                                <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
                                <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
                                    {/* <HERBUI.Navbar collapseOnSelect expand="lg" className="HERB-Top-Nav" variant="dark"> */}
                                    <HERBUI.Nav className="text-center">
                                        <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                                            <b  >Know Your CFMS ID</b>
                                        </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <HERBUI.Nav.Link className="nav_link1" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} >
                                            <b>HERB Mobile App</b>
                                        </HERBUI.Nav.Link>
                                        <HERBUI.Nav.Link onClick={() => newAlerts()} className="nav_link1">
                                            <b>AP Budget 2023-24 Mobile App<img src="../img/gif_new.gif" alt="new" /></b>
                                        </HERBUI.Nav.Link>
                                        <HERBUI.Nav.Link onClick={() => usermanuals()} className="nav_link1">
                                            <b>User Manuals<img src="../img/gif_new.gif" alt="new" /></b>
                                        </HERBUI.Nav.Link>
                                        <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                                            <b  >GOs, Circulars, Memos</b>
                                        </HERBUI.Nav.Link>
                                        <HERBUI.Nav.Link href={'https://apfinance.gov.in/Budget2324/'} className="nav_link1">
                                            <b>AP Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>
                                        </HERBUI.Nav.Link>
                                    </HERBUI.Nav>
                                    {/* </HERBUI.Navbar> */}
                                </HERBUI.Navbar.Collapse>
                            </HERBUI.Navbar>
                        </HERBUI.Col>
                    </HERBUI.Row>
                </HERBUI.Container>

            </FormikProvider>
        </>
    );
}
export default LoginPageAuthentication;