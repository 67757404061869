/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as HERBUI from "react-bootstrap";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import Header from "./Header";
import * as Yup from 'yup';
import Footer from "./Footer";
import "react-datepicker/dist/react-datepicker.css";
import { allowNumbersOnly } from "../components/Functions";
import { AiFillWarning } from "react-icons/ai";
import { CommonAjaxPostRequest } from "../Api/CommonAjaxUtils";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { GENERATE_OTP_URL, GET_DETAILS_BY_AADHAR_DOB, VERIFY_OTP_URL } from "../Api/APIURLS";
export default function KnowYourCfmsId() {
    const Ref = useRef(null);
    const [buttonType, setButtonType] = useState("Generate OTP");
    const [otpStatus, setOtpStatus] = useState(false);
    const [timer, setTimer] = useState('00:00');
    const [resend, setResend] = useState(true);
    const [details, setDetails] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const dispatch = useDispatch()
    const [otpResponse, setOtpResponse] = useState();
    const [resendTimerShow, setResendTimerShow] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const formik = useFormik({
        initialValues: { aadharId: "", dob: "", doj: "", mobileNumber: "", otp: "", secureKey: "" },
        validationSchema: Yup.object({
            mobileNumber: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            if (buttonType === 'Generate OTP') {
                sendOTP()
            }
            else if (buttonType === 'Verify OTP') {
                verifyOTPHandler()
            }
        }
    })
    function sendOTP(values) {
        setResend(true);
        formik.setFieldValue("otp", '')
        let params = { mobileNo: formik?.values?.mobileNumber }
        // CommonAjaxGetRequestWithOutParamsWithOutAuthorization(GENERATE_OTP_URL + "/" + formik?.values?.mobileNumber, dispatch).then(res => {
        CommonAjaxPostRequest(GENERATE_OTP_URL, params, dispatch).then(res => {
            if (res) {
                if (res.SCODE === "01") {
                    Swal.fire({ text: res.SDESC, icon: 'success', confirmButtonColor: '#3085d6', });
                    formik.setFieldValue("secureKey", res.secureKey)
                    setOtpStatus('sent')
                    clearTimer(getDeadTime());
                }
                else if (res.SCODE === "02") {
                    Swal.fire({ text: res.SDESC, icon: 'warning', confirmButtonColor: '#3085d6', });
                }
            }
        }).catch(() => setTimeout(() => {
            // call   
        }, 1500))
    }
    const clearTimer = (e) => {
        setTimer('01:00');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
    function verifyOTPHandler() {
        let params = { mobileNo: formik?.values?.mobileNumber, otp: formik.values.otp, secureKey: formik.values.secureKey }
        CommonAjaxPostRequest(VERIFY_OTP_URL, params, dispatch).then(res => {
            if (res) {
                if (res.SCODE === "01") {
                    setOtpResponse("valid")
                    // setResendTimerShow("");
                    Swal.fire({ text: res.SDESC, icon: 'success', confirmButtonColor: '#3085d6' }).then(function () {
                        getDetails()
                    })
                }
                else if (res.SCODE === "02") {
                    // setResendTimerShow(0);
                    if (timer.toString() === '00:00') {
                        setResend(false);
                    } else {
                        getDeadTime();
                        setResend(true)
                    }
                    formik.setFieldValue("otp", '');
                    setOtpResponse("invalid");
                    setErrorMessage(res.SDESC)
                }
            }
        })
    }
    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }
    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
        if (total === 0) {
            setResend(false);
        }
    }
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total,
            minutes, seconds
        };
    }
    useEffect(() => {
        setOtpResponse('')
        setResend(true);
        formik.setFieldValue("otp", '');
        setResendTimerShow(0);
    }, [])
    function getDetails(values) {
        let params = { mobileNo: formik.values.mobileNumber }
        let url = GET_DETAILS_BY_AADHAR_DOB
        // CommonAjaxGetRequestWithOutParamsWithOutAuthorization(url, dispatch).then(response => {
        CommonAjaxPostRequest(url, params, dispatch).then(response => {
            if (response.SCODE === "01") {
                setDetails(response.DATA[0])
                setShowDetails(true)
            }
            else if (response.SCODE === "02") {
                Swal.fire({ text: response.SDESC, icon: 'error', confirmButtonColor: '#3085d6', });
            }
        })
    }
    return (
        <>   <Header />
            <div className="main_section"> </div>
            <HERBUI.Container style={{ marginTop: "80px" }}>
                <HERBUI.Row>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                        <div className="inner-herbpage-service-title1">
                            <h1> Know Your CFMS ID </h1>
                        </div>
                    </HERBUI.Col>
                </HERBUI.Row>
            </HERBUI.Container>
            <HERBUI.Container className=" bg-white">
                <HERBUI.Row className="pt-5" >
                    <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        &nbsp;
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <HERBUI.Row>
                                    <HERBUI.Col xs={12} sm={12} md={12} lg={112} xl={12} xxl={12}>
                                        <div className='alert alert-info'>
                                            <li> <span className='mb-1'>To Change Mobile Number: Visit <b>ESS(Employee Self Services)</b></span>&nbsp;&nbsp;</li>
                                        </div>
                                    </HERBUI.Col>
                                </HERBUI.Row>
                                <br />
                                <HERBUI.Row>
                                    <HERBUI.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                    </HERBUI.Col>
                                    <HERBUI.Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                        <HERBUI.InputGroup className="mb-4p5">
                                            <span className="label-text-style"> Mobile Number </span>
                                            <Field type='text' name="mobileNumber"
                                                id="mobileNumber"
                                                maxLength={10}
                                                onKeyPress={(e) => allowNumbersOnly(e)}
                                                className={'form-control'}
                                                autoComplete="off" />
                                            <ErrorMessage className="text-error" name="mobileNumber" component="div" />
                                        </HERBUI.InputGroup>
                                    </HERBUI.Col>
                                    <HERBUI.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                    </HERBUI.Col>
                                </HERBUI.Row>
                                {otpStatus &&
                                    <>
                                        <HERBUI.Row>
                                            <h4>Check your Phone</h4>
                                            <p>Please enter OTP received on your mobile number.</p>
                                            <HERBUI.Col className="mt-4" xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                <HERBUI.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"  ><span className="mandatory" >*</span>Enter the OTP : </span>
                                                    <Field type='text' name="otp" className="form-control mandatoryField" maxLength={6} onKeyPress={allowNumbersOnly}
                                                        placeholder="_ _ _ _ _ _" autoComplete="off" style={{ letterSpacing: '10px' }} />
                                                    <ErrorMessage className="text-error" name="otp" component="div" />
                                                </HERBUI.InputGroup>
                                                {
                                                    otpResponse !== undefined && otpResponse !== null && otpResponse !== '' && otpResponse === "invalid" &&
                                                    <div className="col-12 col-md-8 offset-md-2 alert alert-danger alert-dismissible fade show" role="alert" style={{ width: '450px', height: '60px', marginTop: '5px' }}>
                                                        <p style={{ fontSize: '14px', }}> <AiFillWarning style={{ fontSize: '18px' }} /> {errorMessage} </p>
                                                        <button type="button" onClick={() => { setOtpResponse(''); }} className="btn btn-close" data-dismiss="alert"></button>
                                                    </div>
                                                }
                                            </HERBUI.Col>
                                            <HERBUI.Col className="mt-4" xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                <HERBUI.InputGroup className="mb-4p5">
                                                    <button type="submit" className="btn btn-success"
                                                        disabled={formik?.values?.otp?.length !== 6}
                                                        onClick={() => { setButtonType("Verify OTP"); }}
                                                    >Verify-OTP</button>
                                                    &nbsp;&nbsp;
                                                </HERBUI.InputGroup>
                                            </HERBUI.Col>
                                        </HERBUI.Row>

                                        {
                                            resendTimerShow === 0 &&
                                            <HERBUI.Row>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                    <button className='btn btn-primary' onClick={() => sendOTP()} disabled={resend}>Resend OTP</button>
                                                </HERBUI.Col>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                    <h2 style={{ fontSize: '12px' }}> Available in {timer} min</h2>
                                                </HERBUI.Col>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                </HERBUI.Col>
                                            </HERBUI.Row>
                                        }
                                    </>
                                }
                                {otpStatus === false &&
                                    <HERBUI.Row>
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        </HERBUI.Col>
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                            <HERBUI.Button variant="success" type="submit" className="btn_success">
                                                {buttonType}
                                            </HERBUI.Button>
                                        </HERBUI.Col>
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        </HERBUI.Col>
                                    </HERBUI.Row>
                                }
                                <br></br>
                                <br></br>
                                {showDetails &&
                                    <HERBUI.Row >
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>&nbsp;</HERBUI.Col>
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                            <HERBUI.ListGroup>
                                                <HERBUI.ListGroupItem><b>CFMS ID : </b> {details.cfmsid}</HERBUI.ListGroupItem>
                                                <HERBUI.ListGroupItem><b>HRMS ID : </b> {details.hrmsid}</HERBUI.ListGroupItem>
                                                <HERBUI.ListGroupItem><b>Name : </b>{details.surname?.trim() + " " + details?.name}</HERBUI.ListGroupItem>
                                            </HERBUI.ListGroup>
                                            <span > </span>

                                        </HERBUI.Col>
                                        <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>&nbsp;</HERBUI.Col>
                                    </HERBUI.Row>
                                }
                            </Form>
                        </FormikProvider>
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        &nbsp;
                    </HERBUI.Col>
                </HERBUI.Row>
            </HERBUI.Container>
            <Footer />
        </>
    )
}