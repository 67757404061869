/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as HERBUI from "react-bootstrap";
import "../loginandlandingpage.css";
import { FiPower } from "react-icons/fi";
import { BiLock } from "react-icons/bi";
import DashboardCardBudget from "../components/DashboardCardBudget";
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { CommonAjaxGetRequestWithOutParams } from "../Api/CommonAjaxUtils";
import { SERVICES_URL, LOGIN_PAGE_URL, CHANGE_PASSWORD_UI_URL, GET_RETIRING_EMP_COUNT, PAYROLL_UI_URL } from "../Api/APIURLS";
// import { SERVICES_URL, LOGIN_PAGE_URL, CHANGE_PASSWORD_UI_URL, EMPLOYEE_COUNT, SRConfirmation, PRR_UI_URL } from "../Api/APIURLS";
import usermanualddomandalmapping from "../components/Pdf/User_Manual_DDO_Mandal_Mapping.pdf"
import { useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { ClearLocalStorage } from "../components/Functions";
function Dashboard() {
  let parentIdsList = [];
  const [tabs, setTabs] = useState()
  const dispatch = useDispatch();
  const history = useHistory();
  const [context, setContext] = useState(false);
  const [retirementData, setRetirementData] = useState({})
  // const [emp, setEmp] = useState(0)
  const [servicesExists, setServicesExists] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      if (localStorage.getItem('serviceList') === null) {
        CommonAjaxGetRequestWithOutParams(SERVICES_URL + localStorage.getItem("userId"), dispatch).then((response) => {
          setContext(false);
          if (response !== '' && response !== null && response !== undefined && Object.keys(response).length > 0) {
            setContext(true);
            localStorage.setItem("serviceList", JSON.stringify(response));
            setTabs(response)
            setServicesExists(true)
            // CommonAjaxGetRequestWithOutParams(EMPLOYEE_COUNT, dispatch).then((count) => {
            //   if (count !== '' && count !== null && count !== undefined) {
            //     setEmp(count)
            //     localStorage.setItem('count', JSON.stringify(count))
            //   }
            // })
          }
          else {
            setContext(true);
            setServicesExists(false)
            dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
          }
        });
      }
      else {
        setContext(true);
        setServicesExists(true)
        let servicesList = JSON.parse(localStorage.getItem('serviceList'));
        // let count = JSON.parse(localStorage.getItem('count'))
        setTabs(servicesList)
        // setEmp(count)
      }
    }
    else {
      Swal.fire({
        text: 'Session Expired!',
        icon: 'error',
        confirmButtonColor: '#3085d6', confirmButtonText: "Ok"
      }).then(function (isConfirm) {
        if (isConfirm.value) {
          ClearLocalStorage()
          window.location.href = LOGIN_PAGE_URL
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 
    CommonAjaxGetRequestWithOutParams(GET_RETIRING_EMP_COUNT, dispatch).then((response) => {
      if (response !== '' && response !== null && response !== undefined) {
        if (response.SCODE === "01") {
          setRetirementData(response)
        }
        else if (response.SCODE === "02") {
          setRetirementData({})
          dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
        }
      }
    });
  }, [])

  function handleLogOut() {
    history.push("/logOut")
    // CommonAjaxTokenInvalidate(TOKEN_INVALIDATE_URL, dispatch).then(res => {
    //   if (res !== '' && res !== undefined && res !== null) {
    //     ClearLocalStorage()
    //     Swal.fire({
    //       text: res,
    //       icon: 'success',
    //       confirmButtonText: 'Ok',
    //       confirmButtonClass: "swal-button--confirm"
    //     }).then(
    //       function () {
    //         history.push("/login")
    //       }
    //     );
    //   }
    // })
  }

  function ChangePassword() {
    window.location.href = CHANGE_PASSWORD_UI_URL
  }
  // function srcounts(){
  //   window.location.href = SRConfirmation
  // }
  return (
    <div className="Dashboard" style={{ display: context ? "block" : "none" }}>
      <HERBUI.Container fluid>
        <HERBUI.Row>
          <HERBUI.Navbar collapseOnSelect expand="lg" fixed="top" className="HERB-Top-Nav" style={{ posistion: "fixed", top: "0px" }} >
            <HERBUI.Navbar.Brand href="#home">
              <img
                src="img/herb.svg"
                width="250"
                height="45"
                className="d-inline-block align-top"
                alt="HERB"
              />
            </HERBUI.Navbar.Brand>
            <HERBUI.Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
              <HERBUI.Nav className="me-auto"></HERBUI.Nav>
              <HERBUI.Nav>
                <HERBUI.NavDropdown
                  className="header-dropdown-setting"
                  title={(localStorage.getItem("displayName") === null || '' || undefined) ? '' : <b>
                    <span>{"Welcome:"}</span>  &nbsp;
                    {(localStorage.getItem('displayName') !== undefined &&
                      localStorage.getItem('displayName') !== null &&
                      localStorage.getItem('displayName') !== "undefined") ?
                      JSON.parse(localStorage.getItem('displayName')) : ""} ({(localStorage.getItem("userName") === null || '' || undefined) ? '' : JSON.parse(localStorage.getItem('userName'))})
                  </b>}
                  id="basic-nav-dropdown"
                >

                  <HERBUI.NavDropdown.Item onClick={ChangePassword}>
                    <BiLock size={25} onClick={ChangePassword} /> Change Password
                  </HERBUI.NavDropdown.Item>
                  <HERBUI.NavDropdown.Item onClick={() => handleLogOut()}>
                    <FiPower size={23} onClick={() => handleLogOut()} /> Sign Out
                  </HERBUI.NavDropdown.Item>
                </HERBUI.NavDropdown>
              </HERBUI.Nav>
            </HERBUI.Navbar.Collapse>
          </HERBUI.Navbar>
        </HERBUI.Row>
      </HERBUI.Container>
      <div className="main_section">
      </div>
      <HERBUI.Container style={{ marginTop: "80px" }}>
        <HERBUI.Row>
          <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
            <div className="inner-herbpage-service-title1 ">
              <h1>Dashboard</h1>
            </div>
          </HERBUI.Col>
        </HERBUI.Row>
      </HERBUI.Container>
      {servicesExists === false &&
        <HERBUI.Container>
          <HERBUI.Row>
            <HERBUI.Col xs={12} sm={12} md={12} lg={10} xl={12} xxl={12}>
              <div className='alert alert-info'>
                <span className='mb-1'>Services are not assigned to you. Please contact Helpdesk.</span>
              </div>
            </HERBUI.Col>
          </HERBUI.Row>
        </HERBUI.Container>
      }
      {servicesExists &&
        <HERBUI.Container>
          <Tabs>
            {retirementData?.SCODE === "01" &&
              <span class="blinking" style={{ color: 'green', fontSize: '15px' }}>Pre Retirement Service Verification pending for
                <b>{retirementData?.RETIRING_EMPS_COUNT}</b> Employees in your login.
                <a href={PAYROLL_UI_URL + "/DDORequest"}> Click Here</a>&nbsp;To Forward Service Verification Data</span>
            }
            <TabList>
              {tabs !== undefined && tabs?.tabHeader?.tabheaderDetails.filter(t => t.tileDisplay === true).map(tab => {
                return (
                  <Tab>
                    <img src={tab.imageUrl} className="tabiconc" alt="MasterData" />
                    <p className="tab-cap" moduleType={tab.moduleType}>{tab.tileTitle}<br /><br /></p>
                  </Tab>
                )
              })
              }
              {/* <Tab>
                PRR
              </Tab> */}
            </TabList>
            {tabs !== undefined && Object.entries(tabs?.tabData).map(([key, val]) => {
              return (
                <TabPanel>
                  <HERBUI.Container>
                    <HERBUI.Row>
                      <HERBUI.Accordion defaultActiveKey='0' flush >
                        <HERBUI.Accordion.Item eventKey="0">
                          <HERBUI.Accordion.Header><b>Instructions:</b></HERBUI.Accordion.Header>
                          <HERBUI.Accordion.Body>
                            <div className='alert alert-info blink'>
                              <ol><b>Verification of qualifying service after 25 years service:</b>
                                <li className='mb-2'>
                                  Where a Government servant completes twenty-five years of
                                  service or is left with five years of service before the date of retirement the
                                  Head of Office or Department concerned, as the case may be, shall send the
                                  service particulars to the Accountant-General who shall verify them in
                                  accordance with the rules for the time being in force, determine the qualifying
                                  service and record a certificate that the service up to the specified date has
                                  been accepted in audit for purposes of pension and communicate the period
                                  of qualifying service so determined.</li>
                                <li className='mb-2'>
                                  In the case of Class IV and other low paid Government servants
                                  of equivalent rank the Head of the Office shall verify the service
                                  particulars as indicated in sub-rule (1) and record a certificate
                                  in the service book of the employee as per the said sub-rule.</li>
                                <li className='mb-2'>
                                  Verification referred to in sub-rule (1) and (2) shall be subject
                                  to final verification of qualifying service which shall be made at
                                  the time of retirement of the Government servant.</li>
                                <br />

                              </ol>
                            </div>
                            <div className='alert alert-info'>
                              <ol>
                                <span style={{ color: "red" }}>Top Priority:</span>
                                A facility "office address details " has been developed and enabled in the DDO logins for capturing of addresses and jurisdiction of all the org units in the state.
                                <span className='mb-1'>&nbsp;Please click here for user manual:
                                  <a href={usermanualddomandalmapping} target="_blank" rel="noreferrer">User Manual</a>
                                  &emsp;
                                </span>
                              </ol>
                            </div>


                          </HERBUI.Accordion.Body>
                        </HERBUI.Accordion.Item >
                      </HERBUI.Accordion>
                    </HERBUI.Row>
                  </HERBUI.Container>

                  <HERBUI.Container>
                    <HERBUI.Row>
                      <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {val !== undefined && val !== "" && val.parentServices.length > 0 &&
                          val.parentServices.filter((service) => {
                            parentIdsList?.push(service.serviceId);
                            return service.childServices.length !== 0
                          }).map((item) => (
                            <>
                              <HERBUI.Row style={{ paddingBottom: "20px" }}>
                                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                  <div className="inner-herbpage-service-title2">
                                    <h1>{item.serviceName} </h1>
                                  </div>
                                </HERBUI.Col>
                                {(item.childServices.length !== 0) ?
                                  item.childServices.map((child) => {
                                    parentIdsList.push(child.serviceId);
                                    return <DashboardCardBudget className="mb-3 ellips bg-custgreen "
                                      title={child.serviceName}
                                      url={child.targetUrl}
                                      index={child.serviceId}
                                      module={key}
                                    />
                                  }) : ''
                                }
                              </HERBUI.Row>
                            </>
                          ))}
                      </HERBUI.Col>
                    </HERBUI.Row>
                    <HERBUI.Row style={{ paddingBottom: "50px" }}>
                      <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="inner-herbpage-service-title2">
                          <h1>Others</h1>
                        </div>
                      </HERBUI.Col>
                      {val !== undefined && val.otherServices
                        !== undefined && val.otherServices !== ''
                        &&
                        val.otherServices.map((item) => (
                          <>
                            <DashboardCardBudget
                              title={item.serviceName}
                              index={item.serviceId}
                              url={item.targetUrl}
                              module={key}
                            />
                          </>
                        ))}
                    </HERBUI.Row>
                  </HERBUI.Container>
                </TabPanel>
              )
            })
            }
            {/* <TabPanel>
              <DashboardCardBudget
                title={"PRR"}
                index={0}
                url={PRR_UI_URL + "/panchayatUploadingdata"}
                module={"PRR"}
              />
            </TabPanel> */}
          </Tabs>
        </HERBUI.Container>
      }
    </div >
  );
}

export default Dashboard;
