import * as HERBUI from "react-bootstrap";
import {  FiHome } from "react-icons/fi";

const Header = () => {
   
    return (
        <HERBUI.Container fluid>
            <HERBUI.Row>
                <HERBUI.Navbar collapseOnSelect expand="lg" fixed="top" className="HERB-Top-Nav" style={{posistion:"fixed",top:"0px"}}  >
                    <HERBUI.Navbar.Brand href="/home">
                        <img
                            src="../img/herb.svg"
                            width="250"
                            height="45"
                            className="d-inline-block align-top"
                            alt="HERB"
                        />
                    </HERBUI.Navbar.Brand>
                    <HERBUI.Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
                        <HERBUI.Nav className="me-auto"></HERBUI.Nav>
                        <HERBUI.Nav>
                            <HERBUI.Nav.Link href="/login" className=" "><FiHome />&nbsp;Home </HERBUI.Nav.Link>
                        </HERBUI.Nav>
                    </HERBUI.Navbar.Collapse>
                </HERBUI.Navbar>
            </HERBUI.Row>
        </HERBUI.Container>
    );
}
export default Header;
