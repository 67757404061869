import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as HERBUI from "react-bootstrap";
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import Header from "./Header";
import Footer from "./Footer";
import {  CommonAjaxPostRequest } from "../Api/CommonAjaxUtils";
import { LOGIN_API_BASE_URL,LOGIN_PAGE_URL } from "../Api/APIURLS";
import { allowNumbersOnly } from "../components/Functions";
import { useDispatch } from "react-redux";
export default function ForgotPassword() {
    const [name, setName] = useState("Get OTP")
    const [otpflag, setOtpflag] = useState(false);
    const [zeroCheck, setNonZeroCkeck] = useState({ mobileNo: false });
    const dispatch =useDispatch()
    const [forgotPasswordInitialValues, setForgotPasswordInitialValues] = useState(
        {
            cfmsId: '',
            name: '',
            mobileNo: '',
            email: '',
            enteredOtp: '',
            secureKey:'',
            showOtp: otpflag
        }
    );
    const [disabledFlags] = useState({ "name": true, "mobileNo": false, "email": false, "otp": false, "save": true })
    const SaveUpdateMobileNumber = (values) => {
        let params =
            { "cfmsId": values.cfmsId, "mobileNo": values.mobileNo, "email": values.email }
        if (otpflag === false) {
            CommonAjaxPostRequest(LOGIN_API_BASE_URL + 'forgotpasswordverification/submit', params,dispatch).then(res => {
                if (res) {
                    if (res.SCODE === "01") {
                        Swal.fire({ text: res.SDESC, icon: 'success', confirmButtonColor: '#3085d6', });
                        setOtpflag(true)
                        setName("Submit")
                        setForgotPasswordInitialValues({ ...forgotPasswordInitialValues,secureKey:res.secureKey, showOtp: true })
                    }
                    else if (res.SCODE === "02") {
                        Swal.fire({ text: res.SDESC, icon: 'warning', confirmButtonColor: '#3085d6', });
                    }
                }
            })
        }
        else {
            let params = { cfmsId: values.cfmsId, otp: values.enteredOtp,secureKey:values.secureKey }
            CommonAjaxPostRequest(LOGIN_API_BASE_URL + 'forgotpasswordverification/otp/submit',params,dispatch).then(res=>{
                if (res) {
                            if (res.SCODE === "01") {
                                Swal.fire({ text: res.SDESC, icon: 'success', confirmButtonColor: '#3085d6', confirmButtonText: "Ok", })
                                    .then(function (isConfirm) {
                                        if (isConfirm.value) {
                                            window.location.href=LOGIN_PAGE_URL
                                        }
                                    });
                                setOtpflag(false)
                            }
                            else if (res.SCODE === "02") {
                                Swal.fire({ text: res.SDESC, icon: 'warning', confirmButtonColor: '#3085d6', });
                            }
                        }
            })
        }
    }
    const getDetails = (e) => {
        let params = {"cfmsId":e.target.value}
        if (e.target.value !== '') {
            // CommonAjaxGetRequestWithOutParamsWithOutAuthorization(LOGIN_API_BASE_URL + "forgotpassworddetailsverification/" + e.target.value,dispatch).then(res => {
            CommonAjaxPostRequest(LOGIN_API_BASE_URL + "forgotpassworddetailsverification" ,params,dispatch).then(res => {
                if (res.SCODE === "01") {

                    setForgotPasswordInitialValues({ ...forgotPasswordInitialValues, cfmsId: e.target.value, name: res.name, mobileNo: res.mobileNo, email: res.email })
                    if (parseInt(res.mobileNo) === 0) {
                        setNonZeroCkeck({ ...zeroCheck, mobileNo: false })
                    }

                    else {
                        setNonZeroCkeck({ ...zeroCheck, mobileNo: true })
                    }
                }

                else if (res.SCODE === "02") {
                    Swal.fire({ text: res.SDESC, icon: 'warning', confirmButtonColor: '#3085d6', });
                }
            })
        }
        else {
            Swal.fire({ text: "Cfms Id Should Not be Empty", icon: 'warning', confirmButtonColor: '#3085d6', });
        }
    }
    const cfmsIdOnChange = (e) => {
        setForgotPasswordInitialValues({ ...forgotPasswordInitialValues, cfmsId: e.target.value, name: '', mobileNo: '', email: '' })
        setNonZeroCkeck({ ...zeroCheck, mobileNo: false })
    }
    return (
        <>   <Header />
          <div className="main_section"> </div>
           
            <HERBUI.Container style={{ marginTop: "80px" }}>
                <HERBUI.Row>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                        <div className="inner-herbpage-service-title1">
                            <h1> Forgot Password</h1>
                        </div>
                    </HERBUI.Col>
                </HERBUI.Row>
            </HERBUI.Container>
            <HERBUI.Container className=" bg-white">
                <HERBUI.Row className="pt-5" >
                    <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        &nbsp;
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <Formik
                            initialValues={forgotPasswordInitialValues} enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                                cfmsId: Yup.string().required('required'),
                                showOtp: Yup.boolean(),
                                enteredOtp: Yup.string().when('showOtp', {
                                    is: true,
                                    then: Yup.string().required('required')
                                }),
                            })}
                            onSubmit={(values) => {
                                SaveUpdateMobileNumber(values);
                            }}>
                            {({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => {
                                return (
                                    <div className="empDiv">
                                        <form noValidate name="customForm" onSubmit={handleSubmit}>
                                            <HERBUI.Row>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <HERBUI.InputGroup className="mb-4p5">
                                                        <span className="label-text-style"><b>Cfms Id:</b><span className="text-danger">*</span></span>
                                                        <Field type="text" placeholder="Enter Cfms Id" name="cfmsId" onKeyPress={allowNumbersOnly}
                                                            maxLength="8" onBlur={getDetails} value={values?.cfmsId} onChange={cfmsIdOnChange}
                                                            className={(touched.cfmsId && errors.cfmsId) ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.cfmsId && errors.cfmsId ? (
                                                            <div className="invalid-feedback">{errors.cfmsId}</div>
                                                        ) : null}
                                                    </HERBUI.InputGroup>
                                                </HERBUI.Col>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <HERBUI.InputGroup className="mb-4p5">
                                                        <span className="label-text-style"><b>Employee Name:</b></span>
                                                        <Field type="text" placeholder="Enter Name" name="name"
                                                            value={values?.name} maxLength="35" disabled={true}
                                                            className={(touched.name && errors.name) ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.name && errors.name ? (
                                                            <div className="invalid-feedback">{errors.name}</div>
                                                        ) : null}
                                                    </HERBUI.InputGroup>
                                                </HERBUI.Col>
                                                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <HERBUI.InputGroup className="mb-4p5">
                                                        <span className="label-text-style"><b>Mobile No:</b></span>
                                                        <Field type="text" placeholder="Enter Mobile No" name="mobileNo"
                                                            value={values?.mobileNo}
                                                            maxLength="10" disabled={true}
                                                            className={(touched.mobileNo && errors.mobileNo) ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.mobileNo && errors.mobileNo ? (
                                                            <div className="invalid-feedback">{errors.mobileNo}</div>
                                                        ) : null}
                                                    </HERBUI.InputGroup>
                                                </HERBUI.Col>

                                                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <HERBUI.InputGroup className="mb-4p5">
                                                        <span className="label-text-style"><b>Email Id:</b></span>
                                                        <Field type="text" placeholder="Email Id" name="email"
                                                            value={values?.email}
                                                            maxLength="50"
                                                            disabled={true}
                                                            className={(touched.email && errors.email) ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.email && errors.email ? (
                                                            <div className="invalid-feedback">{errors.email}</div>
                                                        ) : null}
                                                    </HERBUI.InputGroup>
                                                </HERBUI.Col>
                                                <Field type="hidden" name="showOtp" value={otpflag}>
                                                </Field>
                                                {otpflag && <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <HERBUI.InputGroup className="mb-4p5">
                                                        <span className="label-text-style"><b>OTP:</b></span>
                                                        <Field type="text" placeholder="Enter OTP" name="enteredOtp" maxLength="6" onChange={handleChange}
                                                            value={values.enteredOtp} onKeyPress={allowNumbersOnly}
                                                            className={(touched.enteredOtp && errors.enteredOtp) ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.enteredOtp && errors.enteredOtp ? (
                                                            <div className="invalid-feedback">{errors.enteredOtp}</div>
                                                        ) : null}
                                                    </HERBUI.InputGroup>
                                                </HERBUI.Col>}
                                            </HERBUI.Row>

                                            <Field type="hidden" value="cfmsid" />
                                            <>
                                                {disabledFlags.save === true &&
                                                    <HERBUI.Row className=" mb-3">
                                                        <HERBUI.Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                                            &nbsp;
                                                        </HERBUI.Col>
                                                        {zeroCheck.mobileNo === true &&
                                                            <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                                <div className="d-grid gap-2">
                                                                    <button type="submit" className="btn btn-success">{name}</button>
                                                                </div>
                                                            </HERBUI.Col>

                                                        }
                                                        {parseInt(forgotPasswordInitialValues.mobileNo) === 0 &&
                                                            <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <div className="alert alert-success2">
                                                                    <p><strong>Please Update Mobile Number &amp; Email Id <br />
                                                                    </strong></p>
                                                                </div>
                                                            </HERBUI.Col>

                                                        }
                                                    </HERBUI.Row>}
                                            </>
                                        </form>
                                    </div>
                                );
                            }}
                        </Formik>
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        &nbsp;
                    </HERBUI.Col>
                </HERBUI.Row>
            </HERBUI.Container>
            <Footer />
        </>
    )
}