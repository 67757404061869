import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as HERBUI from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import Budget_202324 from '../../src/components/Pdf/Budget_202324.pdf'
import Budget_2023_24_new from '../../src/components/Pdf/Budget_2023_24_new.pdf'
import Budget_meeting_30112022 from '../../src/components/Pdf/Budget_meeting_30112022.pdf'
import AP_BUDGET_PRESENTATION_2023_24_v1_2 from '../../src/components/Pdf/AP_BUDGET_PRESENTATION_2023_24_v1-2.pdf'
import UserManual_01122022 from '../../src/components/Pdf/UserManual_01122022.pdf'

export default function BudgetRelatedPdfDetails() {

    return (<>
        <Header />
        <div className="main_section"> </div>
        <HERBUI.Container style={{ marginTop: "80px" }}>
            <HERBUI.Row>
                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                    <div className="inner-herbpage-service-title1">
                        <h1>Budget 2023-24 Information </h1>
                    </div>
                </HERBUI.Col>
            </HERBUI.Row>
        </HERBUI.Container>
        <HERBUI.Container className=" bg-white">
            <HERBUI.Row className="p-4">

            <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card  style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3"> Guidelines for Uploading budget estimates for 2023-24</HERBUI.Card.Title>
                            <HERBUI.Card.Text>

                                <a href="https://cfms.ap.gov.in/gos/Guidelines.pdf" target="_blank" className="text-black" rel="noreferrer"><img src="../img/gif_new.gif" alt="new" />&nbsp;Guidelines for Uploading budget estimates for 2023-24 for works - instructions- issued &nbsp; </a>&emsp;<br /><br />
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >

                <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card  style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3"> Budget Estimates 2023-24</HERBUI.Card.Title>
                            <HERBUI.Card.Text>

                                <a href={Budget_202324} target="_blank" className="text-black" rel="noreferrer">&nbsp;Finance Department - Budget - Budget Estimates 2023-24 – Comprehensive instructions for the
                                    preparation of Budget Estimates and on-line submission –Issued Dated 28-11-2022 &nbsp; </a>&emsp;<br /><br />
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >
                <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card  style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3">Budget 2023-24-Preliminary Meeting</HERBUI.Card.Title>
                            <HERBUI.Card.Text>

                                <a href={Budget_meeting_30112022} target="_blank" className="text-black" rel="noreferrer">&nbsp;Budget 2023-24-Preliminary Meeting with all the Special Chief
                                    Secretaries/Prl.Secretaries/Secretaries to Government and Heads of Departments on Preparation of Budget 2023-24-Regarding Dated 28-11-2022 </a>&emsp;
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >




                <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3">Pre Budget Meeting Presentation</HERBUI.Card.Title>
                            <HERBUI.Card.Text>

                                <a href={AP_BUDGET_PRESENTATION_2023_24_v1_2} target="_blank" className="text-black" rel="noreferrer">&nbsp;Presentation of Pre Budget Meeting held on 30-11-2022 </a>&emsp;
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >
                <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3">Budget 2023-24 User Manual</HERBUI.Card.Title>
                            <HERBUI.Card.Text>

                                <a href={UserManual_01122022} target="_blank" className="text-black" rel="noreferrer">&nbsp;This document gives a detailed insight into Budget Activities done by HOD / DDO </a>&emsp;
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >

                <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-2" >
                    <HERBUI.Card style={{ minHeight: "255px" }}>
                        <HERBUI.Card.Body>
                            <HERBUI.Card.Title className="text-primary mb-3">Budget 2023-24</HERBUI.Card.Title>
                            <HERBUI.Card.Text>
                                <a href={Budget_2023_24_new} target="_blank" className="text-black" rel="noreferrer">&nbsp;Budget 2023-24-Meetings with the Secretariat Departments and HoDs to discuss Budget allocations - Regarding. - dated 07-12-2022. </a>&emsp;
                            </HERBUI.Card.Text>

                        </HERBUI.Card.Body>
                    </HERBUI.Card>
                </HERBUI.Col >


            </HERBUI.Row >
        </HERBUI.Container>
        <Footer />
    </>
    )
}