const Footer = () => {
  return (
    <div className="footer">
      <img
        src="../img/apcfss.svg"
        width="100"
        height="30"
        className="d-inline-block align-top"
        alt="HERB"
      />
    </div>
  )
}
export default Footer;