import { LOGIN_DOMAIN } from '../Api/APIURLS';

var createGuest = require('cross-domain-storage/guest');
export function allowNumbersOnly(e) {
    var keyCode = e.keyCode === 0 ? e.charCode : e.keyCode;
    var value = Number(e.target.value + e.key) || 0;
    if (keyCode >= 48 && keyCode <= 57) {
        return isValidNumber(value);
    }
    else {
        e.preventDefault();
    }
    return false
}
export function isValidNumber(number) {
    return (1 <= number && number <= 10)
}
export function ClearLocalStorage() {
    let mainAppguest = createGuest(LOGIN_DOMAIN);
    localStorage.removeItem("token");
    localStorage.removeItem("serviceList");
    localStorage.clear();
    mainAppguest.remove('token', function (error, data) {
        // foo is now removed
    });
    mainAppguest.remove('userData', function (error, data) {
        // foo is now removed
    });
    mainAppguest.remove('serviceList', function (error, data) {
        // foo is now removed
    });
    mainAppguest.remove('userName', function (error, data) {
        // foo is now removed
    });
    mainAppguest.remove('refresh_token', function (error, data) {
        // foo is now set to 'bar'  
    });
    mainAppguest.remove('userId', function (error, data) {
        // foo is now set to 'bar'  
    });
    mainAppguest.close()
}