import { useEffect } from "react";
import { LOGIN_DOMAIN, LOGIN_PAGE_URL, WORKS_UI_URL } from "../Api/APIURLS";
import Swal from "sweetalert2";
import { ClearLocalStorage } from "../components/Functions";

var createGuest = require('cross-domain-storage/guest');
const WorkDashboard = (props) => {
    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            let url = WORKS_UI_URL;
            redirectUrl(url, "W")
        } else {
            Swal.fire({
                text: 'Session Expired!',
                icon: 'error',
                confirmButtonColor: '#3085d6', confirmButtonText: "Ok"
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    ClearLocalStorage()
                    window.location.href = LOGIN_PAGE_URL
                }
            });
        }
    }, [])
    function redirectUrl(url, module) {
        let mainAppguest = createGuest(LOGIN_DOMAIN);
        localStorage.setItem('url', JSON.stringify(url))
        mainAppguest.set('url', JSON.stringify(url), function (error, data) {
            // foo is now set to 'bar'
        });
        window.location.href = url
    }
    return (
        <></>
    )
}

export default WorkDashboard;