import axios from "axios";
import Swal from "sweetalert2";
import { LOGIN_PAGE_URL } from "./APIURLS";

export const CommonAjaxPostRequest = async (url, values,dispatch) => {
    let res = '';
    try {
        res = await axios({
            url: url,
            method: 'post',
            data: values,
        })
        if (res.status === 200) {

        }
        return res.data
    }
    catch (err) {
        console.error(err);
        failureResponse(err,dispatch);
        
    }
};

export const CommonAjaxGetRequestWithOutParamsWithOutAuthorization = async (url,dispatch) => {

    let res = '';
    try {
        res = await axios({
            url: url,
            method: 'get',

        })
        if (res.status === 200) {

        }
        return res.data
    }
    catch (err) {
        console.error(err);
        failureResponse(err,dispatch);
    }
};

export const CommonAjaxGetRequestWithOutParams = async (url,dispatch) => {

    let token = JSON.parse(localStorage.getItem("token"));
    let res = '';
    try {
        res = await axios({
            url: url,
            method: 'get',
            headers: { Authorization: "Bearer " + token }
        })
        if (res.status === 200) {

        }
      
        return res.data
    }
    catch (err) {
        console.error(err);
        failureResponse(err,dispatch);
        dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
    }
};
export const CommonAjaxTokenInvalidate = async (url, dispatch) => {
    let refresh_token = JSON.parse(localStorage.getItem('refresh_token'))
    let res = ''
    let urlencoded = new URLSearchParams();
    if (refresh_token !== null) {
        urlencoded.append('refresh_token', refresh_token);
        let Headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST"
        }
        try {
            res = await axios.post(url, urlencoded, Headers);
            if (res.status === 200) {

            }
            return res.data
        }
        catch (res) {
            window.location.href=LOGIN_PAGE_URL
        }
        dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });

    }
}
    export function failureResponse(res,dispatch) {
        let errorMessage = "Something Went Wrong. Please Try again";
        if (res.response !== undefined) {
            if (res.response.status === 401 || res.response.status === 403) {
                errorMessage = "UnAuthorised Access";
            }
            else if ((res.response.status === 404) || (res.response.status === 500) || (res.response.status === 400)) {
                if (res.response.data.errorMessage !== null && res.response.data.errorMessage !== undefined && res.response.data.errorMessage.length > 0) {
                    errorMessage = res.response.data.errorMessage;
                }
            }
        }
        else {
            errorMessage = 'Something Went Wrong. Please Try again';
        }
        Swal.fire({
            text: errorMessage,
            icon: 'error',
            confirmButtonColor: '#4962B3',
            confirmButtonText: 'Ok'
        }).then(function () {
            window.location.href = LOGIN_PAGE_URL
        });
        dispatch({type:'HIDE_SPINNER',payload:{key:'showSpinner',value:false} });
    }
    export const CommonAjaxPostLoginRequest = async (url, values,dispatch) => {
        let res = '';
        try {
            res = await axios({
                url: url,
                method: 'post',
                data: values,
            })
            if (res.status === 200) {
    
            }
            return res.data
        }
        catch (err) {
            console.error(err);
           
        }
    };