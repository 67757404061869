/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import * as HERBUI from "react-bootstrap";
import "../loginandlandingpage.css";
import { BiUser } from "react-icons/bi";
import * as Yup from 'yup';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API_URL, BUDGET_PLANNING_UI_URL, BUDGET_UI_URL, ESS_UI_URL, EXPENDITURE_UI_URL, PAYROLL_UI_URL, LOGIN_DOMAIN, SIGN_IN_URL, WORKS_UI_URL, LOGIN_API_BASE_URL } from "../Api/APIURLS";
import { useDispatch } from "react-redux";
import { CommonAjaxGetRequestWithOutParamsWithOutAuthorization, CommonAjaxPostLoginRequest, CommonAjaxPostRequest } from '../Api/CommonAjaxUtils'
import { AiOutlineMobile } from "react-icons/ai";
import Swal from "sweetalert2";
var createHost = require('cross-domain-storage/host');
var createGuest = require('cross-domain-storage/guest');
const SiginPage = () => {
    var storageHost = null;
    var mainAppguest = null;
    const [submitType, setSubmitType] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [otpError, setOtpError] = useState('')
    const [otpStatus, setOtpStatus] = useState('')
    const [showFields, setShowFields] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const intialValues = { username: '', password: '', otp: "", name: "", mobileNo: "", email: "" }
    const validationSchema = Yup.object({
        username: Yup.string().required('Required'),
    })

    useEffect(() => {
        storageHost = createHost([
            {
                origin: LOGIN_DOMAIN,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: EXPENDITURE_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: BUDGET_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: PAYROLL_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: BUDGET_PLANNING_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: ESS_UI_URL,
                allowedMethods: ['get', 'set', 'remove'],
            },
            {
                origin: WORKS_UI_URL,
                allowedMethods: ['get'],
            },
        ]);
    }, [])

    const formik = useFormik({
        initialValues: intialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (submitType === 'Details') {

                setOtpStatus(false)
                setShowErrorMsg(false)
                getDetailsByCfmsId(values)
            }
            if (submitType === 'login' && otpError === true && passwordError === true) {
                localStorage.removeItem("token");
                localStorage.removeItem("serviceList");
                localStorage.clear();
                localStorage.setItem('userId', values.username)
                let params = {
                    username: values.username.trim(),
                    password: values.password.trim(),
                    otp: values.otp
                }
                //   {   "username": "string",   "password": "string",   "otp": "string" }
                CommonAjaxPostLoginRequest(SIGN_IN_URL, params, dispatch).then((response) => {
                    if (response !== "" && response !== null && response !== undefined) {
                        localStorage.setItem("token", JSON.stringify(response.access_token));
                        localStorage.setItem("refresh_token", JSON.stringify(response.refresh_token));
                        localStorage.setItem('userData', JSON.stringify(response))
                        localStorage.setItem('userName', JSON.stringify(values.username))
                        mainAppguest = createGuest(LOGIN_DOMAIN);
                        let displayName = ""
                        const base64Url = response?.access_token.split('.')[1];
                        if (base64Url !== undefined) {
                            const base64 = base64Url.replace('-', '+').replace('_', '/');
                            displayName = JSON.parse(window.atob(base64)).name
                            localStorage.setItem('displayName', JSON.stringify(displayName))

                        }
                        mainAppguest.set('token', JSON.stringify(response.access_token), function (error, data) {
                            // foo is now set to 'bar'
                        });
                        mainAppguest.set('displayName', JSON.stringify(displayName), function (error, data) {
                            // foo is now set to 'bar'
                        });
                        mainAppguest.set('userData', JSON.stringify(response), function (error, data) {
                            // foo is now set to 'bar'
                        });
                        mainAppguest.set('refresh_token', JSON.stringify(response.refresh_token), function (error, data) {
                            // foo is now set to 'bar'  
                        });
                        mainAppguest.set('userId', JSON.stringify(values.username), function (error, data) {
                            // foo is now set to 'bar'  
                        });

                        history.push("/dashboard");
                    }
                    else {
                        dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
                        setShowErrorMsg(true);
                        // formik.setFieldValue('username', '');
                        formik.setFieldValue('password', '');
                        formik.setFieldValue('otp', '');
                    }
                });
            }

        }
    });
    function knowYourChallan() {
        history.push('/knowYourCfmsId')
    }
    function forgotPassword() {
        history.push("/forgotPassword");
    }
    function gos() {
        history.push("/gos");
    }
    function getDetailsByCfmsId(values) {
        CommonAjaxGetRequestWithOutParamsWithOutAuthorization(LOGIN_API_BASE_URL + "forgotpassworddetailsverification/" + values?.username, dispatch).then((res) => {
            if (res !== undefined && res !== null && res !== "" && res.SCODE === '01') {
                formik.setFieldValue('otp', '')
                formik.setFieldValue('password', '')
                formik.setFieldValue('name', res.name)
                formik.setFieldValue('mobileNo', res.mobileNo)
                formik.setFieldValue('email', res.email)
                setShowFields(true)
                setPasswordError(false)
                setOtpError(false)
            }
        })
    }
    function generateOtp() {
        let params =
            { "cfmsId": formik?.values.username, "mobileNo": formik?.values?.mobileNo, "email": formik?.values?.email }
        CommonAjaxPostRequest(LOGIN_API_BASE_URL + 'forgotpasswordverification/submit', params, dispatch).then(res => {
            if (res) {
                if (res.SCODE === "01") {
                    Swal.fire({ text: res.SDESC, icon: 'success', confirmButtonColor: '#3085d6', });
                    setOtpStatus('sent')
                    // clearTimer(getDeadTime());
                }
                else if (res.SCODE === "02") {
                    Swal.fire({ text: res.SDESC, icon: 'warning', confirmButtonColor: '#3085d6', });
                }
            }
        })
    }
    function setSubmit(){
        if(submitType===""){
            setSubmitType('Details')
        }
        if(submitType==='Details'){
            setSubmitType('login')
        }
    }
    return (
        <>
            <FormikProvider value={formik}>
                <HERBUI.Navbar collapseOnSelect expand="lg" className="HERB-Top-Nav" variant="dark">
                    <HERBUI.Container>
                        <HERBUI.Navbar.Brand href="/login">
                            <img
                                src="img/herb.svg"
                                width="250"
                                height="45"
                                className="d-inline-block align-top"
                                alt="HERB"
                            />
                        </HERBUI.Navbar.Brand>
                        <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
                        <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
                            <HERBUI.Nav className="me-auto"></HERBUI.Nav>
                            <HERBUI.Nav>
                                <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                                    <b  >Know Your CFMS ID</b>
                                </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                                    <b  >GOs, Circulars, Memos</b>
                                </HERBUI.Nav.Link>
                            </HERBUI.Nav>
                        </HERBUI.Navbar.Collapse>
                    </HERBUI.Container>
                </HERBUI.Navbar>
                <HERBUI.Row style={{ marginBottom: '50px' }}>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="  " >
                        <img src="img/corner.svg" className="corner" alt="dots" />
                        <div className="main_form">
                            <HERBUI.Container>
                                <HERBUI.Row>
                                </HERBUI.Row>
                            </HERBUI.Container>
                            <form className="form_adjust" style={{ marginTop: '25%' }} onSubmit={formik.handleSubmit}>
                                <div className="inner-herbpage-service-title-sub-np">
                                    <h1 style={{ fontSize: '22px' }}> Login </h1>
                                </div>
                                <HERBUI.InputGroup className="mb-3">
                                    <HERBUI.InputGroup.Text id="basic-addon1" className="group_txt">
                                        <BiUser className="font_size" />
                                    </HERBUI.InputGroup.Text>
                                    <Field name="username" placeholder="User ID" type="text"
                                        aria-label="User ID"
                                        aria-describedby="basic-addon1"
                                        className="form-control" /> &nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success" onClick={() => setSubmit()}>Continue</button>
                                    <ErrorMessage name="username" className="text-error" component="div" />
                                </HERBUI.InputGroup>
                                {showFields &&
                                    <>
                                        <div className='alert alert-info'>
                                            <span className='mb-1'>OTP will be sent to registered Mobile number {formik?.values?.mobileNo}.</span>
                                        </div>
                                        &nbsp;&nbsp;
                                        {otpStatus !== 'sent' &&
                                            <button type="button" className="btn btn-success" onClick={() => generateOtp()}>Generate OTP</button>
                                        }
                                        {otpStatus === 'sent' &&
                                            <HERBUI.InputGroup className="mb-3">
                                                <Field placeholder="Enter OTP" name="otp" type="text"
                                                    aria-label="Password"
                                                    aria-describedby="basic-addon1"
                                                    className="form-control" autoComplete="off"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('otp', e.target.value)
                                                        if (e.target.value !== '') {
                                                            setOtpError(true)
                                                        }
                                                        else {
                                                            setOtpError(false)
                                                        }
                                                    }}
                                                />
                                                {otpError === false &&
                                                    <span className="text-error">Required</span>
                                                }
                                            </HERBUI.InputGroup>
                                        }
                                        {otpStatus === 'sent' &&
                                            <HERBUI.InputGroup className="mb-3">
                                                <Field placeholder="Enter Password" name="password" type="password"
                                                    aria-label="Password"
                                                    aria-describedby="basic-addon1"
                                                    className="form-control" autoComplete="off"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('password', e.target.value)
                                                        if (e.target.value !== '') {
                                                            setPasswordError(true)
                                                        }
                                                        else {
                                                            setPasswordError(false)
                                                        }
                                                    }}
                                                />
                                                {passwordError === false &&
                                                    <span className="text-error">Required</span>
                                                }
                                            </HERBUI.InputGroup>
                                        }
                                    </>
                                }
                                {otpStatus === 'sent' &&
                                    <div className="d-grid gap-2">
                                        <HERBUI.Button variant="primary" type="submit" className="btn_primary" onClick={() => setSubmit()}>
                                            Sign in
                                        </HERBUI.Button>
                                        <a className="text-end forgot mb-4" style={{ zIndex: "9994" }} onClick={() => forgotPassword()}>Forgot Password ?</a>
                                    </div>
                                }
                                {showErrorMsg &&
                                    <div className="row">
                                        <div className="col-12  col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <div className="  alert alert-danger alert-dismissible fade show" role="alert">
                                                <strong>Invalid OTP/Username/Password </strong>
                                                <button type="button" onClick={() => setShowErrorMsg(false)} className="btn btn-close" data-dismiss="alert"></button>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </form>
                        </div>
                        <br/>
                        <HERBUI.Row>
                            <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mx-5>
                                <a className="text-light" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} target="_blank" rel="noreferrer">
                                    <div className="text-center  mx-5 mb-2 bg-primary p-3"> <AiOutlineMobile className="landing-icon-for-demo text-light" />
                                        Download Employee Mobile APP (HERB)
                                    </div>
                                </a>
                            </HERBUI.Col>
                        </HERBUI.Row>
                        <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
                            <b>Note:  </b><br />
                            <span className='mb-1'>
                                <ol className='mt-2'>
                                    <li><small> Monthly regular salary bill submissions (including Outsourced in APCOS portal) are allowed from the 16th to the 25th as per schedule.</small></li>
                                    <li><small>Bill Submission is not permitted after 25th.</small></li>
                                </ol>
                            </span>
                        </div>
                    </HERBUI.Col>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
                </HERBUI.Row>
            </FormikProvider>
        </>
    );
}
export default SiginPage;