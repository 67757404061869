import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as HERBUI from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import assignLocation from "../components/Pdf/AssignLocation.pdf";
import mobileNumberUpdation from "../components/Pdf/MobileNumberUpdationThroughDDOLogin.pdf";

export default function UserManuals() {

    return (<>
        <Header />
        <div className="main_section"> </div>
        <HERBUI.Container style={{ marginTop: "80px" }}>
            <HERBUI.Row>
                <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                    <div className="inner-herbpage-service-title1">
                        <h1>User Manuals </h1>
                    </div>
                </HERBUI.Col>
            </HERBUI.Row>
        </HERBUI.Container>
        <HERBUI.Container className=" bg-white">
            <HERBUI.Row className="p-4">
            {/* <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
              <span className='mb-1'>
                <ol className='mt-2'>
                  <li><small> <a href={UserManualDDO1} target="_blank" rel="noreferrer">User Manual to Upload Details of Works Recorded but not Uploaded in CFMS </a><img src="../img/gif_new.gif" alt="new" /> </small></li>
                </ol>
              </span>

            </div> */}
            <div>
              <b>APFRS MOBILE APP :  </b><br />
              <span className='mb-1'>
                <ol className='mt-2'>
                  <li><small>  <a href={assignLocation} target="_blank" rel="noreferrer">User Manual for DDO's to Assign Locations</a> </small></li>
                  <li><small> <a href={mobileNumberUpdation} target="_blank" rel="noreferrer">User Manual for DDO's to Employee Mobile Number Updation</a> </small></li>
                </ol>
              </span>

            </div>
            </HERBUI.Row >
        </HERBUI.Container>
        <Footer />
    </>
    )
}