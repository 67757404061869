/* eslint-disable react-hooks/exhaustive-deps */
import React  from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as HERBUI from "react-bootstrap"; 
import Header from "./Header"; 
import Footer from "./Footer";
// import { IoDocumentTextOutline } from "react-icons/io5";
// import PdfFilesCircularMemoRecent from '../../src/components/Pdf/RPS_2022_Govt instns_7_20_02_ 2022.pdf'
// import PdfFiles from '../../src/components/Pdf/Cir Memo 26.01.2022.pdf'
// import Sample from '../../src/components/Pdf/PRC Pay Fixation 2022 - User Manual_Final.pdf'
// import PayBill from '../../src/components/Pdf/Modified User Manual Payroll_BILL_PREPARATION.pdf'
// import payslipweb from '../../src/components/Pdf/PAYSLIPDESKTOPAPPLICATION.pdf'
// import PdfFilesCircularMemo from '../../src/components/Pdf/CircularMemo.pdf'
// import GO5GSWS from '../../src/components/Pdf/GO5gsws.pdf';
// import GO175GSWS from '../../src/components/Pdf/GO175gsws.pdf';
// import IncomeTax from '../../src/components/Pdf/IncomeTax2021-22.pdf';
// import IncomeTaxOld from '../../src/components/Pdf/IncomeTax2021-22 old.pdf';
// import assignLocation from "../../src/components/Pdf/AssignLocation.pdf";
// import mobileNumberUpdation from "../../src/components/Pdf/MobileNumberUpdationThroughDDOLogin.pdf";
export default function Gos() {
    return (
        <>   <Header />
            <div className="main_section"> </div>
            <HERBUI.Container style={{ marginTop: "80px" }}>
                <HERBUI.Row>
                    <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                        <div className="inner-herbpage-service-title1">
                            <h1> Gos, Circulars, Memos </h1>
                        </div>
                    </HERBUI.Col>
                </HERBUI.Row>
            </HERBUI.Container>
            <HERBUI.Container className=" bg-white">
            <HERBUI.Row className="pt-4">
      <div>
      <HERBUI.Accordion>
      <HERBUI.Accordion.Item eventKey="0">
        <HERBUI.Accordion.Header>Pensions</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
        <ul class="list-group list-group-flush border">
        <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2016FIN_MS221_220821_104336.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.221</a></li>
           <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/07052014FIN_MS97.PDF' target="_blank" rel="noreferrer">GO MS NO.97</a></li>
           <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Payment_of_retirement_benefits_on_the_day_of_retirement.PDF' target="_blank" rel="noreferrer">G.O.MS.NO.100</a></li></ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      <HERBUI.Accordion.Item eventKey="1">
        <HERBUI.Accordion.Header>PRC</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/5DAs.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.8</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/AQP.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.30</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/CCA.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.29</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/DR20220117_20552916.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.09</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Funeral_Charges.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.105</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/G.O_No_106.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.106</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_18.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.18</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_NO_27_HRA.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.27</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_NO_28_HRA.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.28</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_Ms_No_100.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.100</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_Ms_No_102.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.102</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_Ms_No_103.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.103</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_NO_104.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.104</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GOMS15Superannuationageenhanceto62years.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.15</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Medical_Reimbursement_extension.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.192</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/MTS_TO_CONTRACT.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.5</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/MTS_TO_NMRS.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.6</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/outsourcing.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.7</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/PENSION.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.02</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/PRC_2022_Allowances_101.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.101</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/RPS_2022.pdf' target="_blank" rel="noreferrer">G.O.MS.NO.1</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      
      <HERBUI.Accordion.Item eventKey="2">
        <HERBUI.Accordion.Header>RTC</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/ACT_NO_36.pdf' target="_blank" rel="noreferrer">ACT NO 36</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_50.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 50</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_51.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 51</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_52.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 52</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO2_OF_GAD_CABINET.pdf' target="_blank" rel="noreferrer">G.O.MS.No.2</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/MEMO_ON_RTC_PF_ELEMENTS.pdf' target="_blank" rel="noreferrer">MEMO ON RTC PF ELEMENTS</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/prc_go_113.pdf' target="_blank" rel="noreferrer">G.O.MS.No. 113</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/prc_go_114.pdf' target="_blank" rel="noreferrer">G.O.MS.No. 114</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      <HERBUI.Accordion.Item eventKey="3">
        <HERBUI.Accordion.Header>PD ACCOUNTS</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/99_PD_ACCOUNTS.pdf' target="_blank" rel="noreferrer">G.O. Ms. No.: 99</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/145_PD_ACCOUNTS.PDF' target="_blank" rel="noreferrer">G.O.MS.No.145</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_125_PDAs5_14.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.125</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_165_PDAs9_17.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 165</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO20PDAs_3_20.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 20</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GOMSNO43.pdf' target="_blank" rel="noreferrer">CIRCULAR MEMO No.15036-B/485/BG/A1/2000</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>


      <HERBUI.Accordion.Item eventKey="4">
        <HERBUI.Accordion.Header>GIS</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2017_GIS_MS13.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 13</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2018_GIS_MS3.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 3</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2018FIN_MS51.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 51</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2019FIN_MS19.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 19</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2019FIN_MS131.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 131</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2020FIN_MS41.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 41</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GIS_122.pdf' target="_blank" rel="noreferrer">G.O.MS.No. 122</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GIS_calculation_upto_07_2022.pdf' target="_blank" rel="noreferrer">G.O.MS.No. 177</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GIS_Go_No_111.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 111</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GIS_MS156.PDF' target="_blank" rel="noreferrer">G.O.MS.No. 156</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_177.pdf' target="_blank" rel="noreferrer">G.O.MS.No. 177</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Go_Ms_No_2.pdf' target="_blank" rel="noreferrer">G.O.MS.No.2</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS81.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.81</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
     
      <HERBUI.Accordion.Item eventKey="5">
        <HERBUI.Accordion.Header>Districts Reorganization</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9952_Marketing.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.52</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9954_Horticulture.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.54</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9955_Sericulture.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.55</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9956_Animal%20Husb.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.56</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9957_Fisheries.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.57</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9958_CAF_CS.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.58</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9959_BCW_Dept.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.59</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9960_SCW_Dept.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.60</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9961_TWD.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.61</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9962_S_Edn.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.62</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9963_Intermediate.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.63</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9965_I_PR.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.65</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9966_SEB.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.66</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9967_Public_Health.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.67</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9968_IPM.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.68</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9969_Drugs_Control.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.69</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9970_DGP_Home.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.70</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9971_Fire_Service.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.71</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9972_ENC_WRD.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.72</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9973_Ground_Water.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.73</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9974_I_C_Export.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.74</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9975_Hndlooms_Mod.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.75</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9977_CCLA.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.77</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9978_Survey.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.78</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9979_Prob_Excise.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.79</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9980_Registration.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.80</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9982_Endown.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.82</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9983_Planning.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.83</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9984_Panchayat_Raj.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.84</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9985_ENC_RWS.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.85</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9986_ENC_PR.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.86</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9987_ENC_R_B.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.87</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9988_Transport.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.88</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9989_WCD.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.89</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/9990_MAUD.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.90</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/AR_posts_provisional_allocation_to_districts.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.124</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/CTDeptEndt.pdf' target="_blank" rel="noreferrer">Revenue Department (Commercial Taxes-I)</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_126.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.126</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_31_Districts_Restructuring_Procedural_Guidelines.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.31</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_64_T_A_Dept.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.64</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GOMs_51_Agriculture.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.51</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GOMs_53_Cooperative.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.53</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>


      <HERBUI.Accordion.Item eventKey="6">
        <HERBUI.Accordion.Header>CPS</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2012FIN_MS196.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.196</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/2019FIN_MS145.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.145</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/5594_INSTS_ON_GO_336.PDF' target="_blank" rel="noreferrer">New Pension System Circular</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/07052014FIN_MS97.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.97</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/20042018FIN_MS47.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.47</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Family_Pension_without_PRAN.pdf' target="_blank" rel="noreferrer">National Pension System Circular</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_112_CPS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.112</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_131CPS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.131</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_22_CSS_REFUND.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.22 CSS REFUND</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_22_PAYMENT_OF_CSS_ARREARS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.22 PAYMENT OF CSS ARREARS</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_54_ERRATA.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.54</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_62_CPS_EXIT_AND_WITHDRAWL_GUIDELINES.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.62</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_107_DCRG_TO_CPS_EMP.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.107</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_112_CPS_FOR_FOREIGN_SERVICE.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.112</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_121_INVALID%20FAMILY%20PENSION.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.121</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_142_INTEREST_ON_LEGACY_AMOUNT.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.142</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_178_%20QUALIFYING_SERVICE.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.178</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_196_UPLOADING_OF_CONTRIBUTIONS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.196</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_226_INTEREST_ON_CONTRIBUTIONS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.226</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_250_UPLOADING_OF_AIS_CONTRIBUTIONS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.250</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_336_REFUND_OF_CPS_TO_GPF.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.336</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_509_CPS_FOR_AIS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.509</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO_MS_IMPL_OF_CPS_IN_AP.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.653</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO22_CPS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.22</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO62_CPS_Exit.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.62</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO107_CPS_FP.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.107</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO121_CPS_FP_IP.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.121</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/GO336_CPS.PDF' target="_blank" rel="noreferrer">G.O.Ms.No.336</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/gratuity_FP_MS47.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.47</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/NPS_Family_Pension_Instructions.pdf' target="_blank" rel="noreferrer">NPS Family Pension Instructions</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      <HERBUI.Accordion.Item eventKey="7">
        <HERBUI.Accordion.Header>BUDGET RELATED</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/46_OBJECT_HEADS.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.46</a></li>
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/69_OBJECT_HEADS.pdf' target="_blank" rel="noreferrer">G.O. MS. No.: 69</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      <HERBUI.Accordion.Item eventKey="8">
        <HERBUI.Accordion.Header>Bill Submission</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">

          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/schedule_for_bill_submission.PDF' target="_blank" rel="noreferrer">G.O.RT.No. 1512</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
      <HERBUI.Accordion.Item eventKey="9">
        <HERBUI.Accordion.Header>GO80</HERBUI.Accordion.Header>
        <HERBUI.Accordion.Body>
         <ul class="list-group list-group-flush border">
          <li class="list-group-item"><a href='http://3.7.237.77:8081/DTA/Downloads/Decentralization.pdf' target="_blank" rel="noreferrer">G.O.Ms.No.80</a></li>
          </ul>
        </HERBUI.Accordion.Body>
      </HERBUI.Accordion.Item>
    </HERBUI.Accordion>

      </div>

            {/* <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-4"/>
                 <HERBUI.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} >
                   <div className=" ">
                     <ul class="list-group list-group-flush border">
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={IncomeTax} target="_blank" rel="noreferrer">Circular Memo. No: 1995193/1/PC-TA/2023 Dated 26-02-2023 &nbsp; <img src="../img/gif_new.gif" alt="new" /></a>&emsp;</li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={IncomeTaxOld} target="_blank" rel="noreferrer">Memo. No: E2/INCOME TAX /2021-22 Dated 20-07-2022 &nbsp; </a>&emsp;</li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={GO5GSWS} target="_blank" rel="noreferrer">GO5 GSWS  </a>&emsp;</li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={GO175GSWS} target="_blank" rel="noreferrer">GO175 GSWS </a>&emsp;</li>
                
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={payslipweb} target="_blank" rel="noreferrer">Payslip Web Application User Manual   </a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" />
                         <a href={PdfFilesCircularMemoRecent} target="_blank" rel="noreferrer">RPS.2022 - Procedural instructions for Pay Confirmation dated 20-02-2022</a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={PdfFilesCircularMemo} target="_blank" rel="noreferrer">RPS.2022 - Procedural instructions for fixation of pay </a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={PdfFiles} target="_blank" rel="noreferrer">Circular Memo</a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={Sample} target="_blank" rel="noreferrer">User Manual for login and basic pay changes data entry </a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={PayBill} target="_blank" rel="noreferrer">User Manual for pay bill submission </a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={assignLocation} target="_blank" rel="noreferrer">User Manual for DDO's to Assign Locations</a></li>
                       <li class="list-group-item"> <IoDocumentTextOutline className="landing-icon-for-demo-list" /> <a href={mobileNumberUpdation} target="_blank" rel="noreferrer">User Manual for DDO's to Employee Mobile Number Updation</a></li>
                     </ul>
                   </div>
                
                 </HERBUI.Col > */}
                 <HERBUI.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-4"/>
                 </HERBUI.Row >
            </HERBUI.Container>
            <Footer />
        </>
    )
}