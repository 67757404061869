/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Swal from "sweetalert2";
import { LOGIN_DOMAIN, TOKEN_INVALIDATE_URL } from "../Api/APIURLS";
// import { BUDGET_PLANNING_UI_URL, BUDGET_UI_URL, ESS_UI_URL, EXPENDITURE_UI_URL, PAYROLL_UI_URL, LOGIN_DOMAIN, TOKEN_INVALIDATE_URL } 
// from "../Api/APIURLS";
import { CommonAjaxTokenInvalidate } from "../Api/CommonAjaxUtils";
import { ClearLocalStorage } from "../components/Functions";
import { useDispatch } from "react-redux";
// import Iframe from 'react-iframe'
var createGuest = require('cross-domain-storage/guest');
const LogOut = () => {

    // let urls = [BUDGET_UI_URL, EXPENDITURE_UI_URL, PAYROLL_UI_URL]
    const dispatch = useDispatch()
    let mainAppguest = createGuest(LOGIN_DOMAIN);
    useEffect(() => {
        CommonAjaxTokenInvalidate(TOKEN_INVALIDATE_URL, dispatch).then(res => {
            if (res !== '' && res !== undefined && res !== null) {
                ClearLocalStorage(mainAppguest)
                Swal.fire({
                    text: 'You have Successfully Logged Out',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonClass: "swal-button--confirm"
                }).then(
                    function () {
                        // history.push("/login")
                        window.location.href = "/login"
                    }
                );
            }
        })

    }, [])
    return (<>
        {/* <Iframe url={BUDGET_UI_URL + "/logOut"}
                    width="0px"
                    height="0px"
                    id=""
                    className=""
                    position="relative"
                    style={{ display: "none" }}
                />
                <Iframe url={EXPENDITURE_UI_URL + "/logOut"}
                    width="0px"
                    height="0px"
                    id=""
                    className=""
                    position="relative"
                    style={{ display: "none" }}
                />
                 <Iframe url={BUDGET_PLANNING_UI_URL + "/logOut"}
                    width="0px"
                    height="0px"
                    id=""
                    className=""
                    position="relative"
                    style={{ display: "none" }}
                />
                <Iframe url={PAYROLL_UI_URL + "/logOut"}
                    width="0px"
                    height="0px"
                    id=""
                    className=""
                    position="relative"
                    style={{ display: "none" }}
                />
                 <Iframe url={ESS_UI_URL + "/logOut"}
                    width="0px"
                    height="0px"
                    id=""
                    className=""
                    position="relative"
                    style={{ display: "none" }}
                /> */}
    </>);
}
export default LogOut;