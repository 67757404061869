/*----------------------------------------------- --- TEST URLS START ------------------------------------------------------------------------------------- */

// export const LOGIN_API_BASE_URL     = "https://api.test.herb.apcfss.in/login/"
export const LOGIN_API_BASE_URL     = "https://api.wmscorp.apcfss.in/login/"
export const MASTER_API_BASE_URL    = "https://api.test.herb.apcfss.in/master/"
export const PAYROLL_API_BASE_URL   = "https://api.test.herb.apcfss.in/hrms/"

/* LOGIN UI URLS IN DEVELOP BRANCH*/
// export const LOGIN_PAGE_URL         = 'https://test.herb.apcfss.in/login';
// export const LOGIN_DOMAIN           = 'https://test.herb.apcfss.in';

export const LOGIN_PAGE_URL         = 'https://wmscorp.apcfss.in/login';
export const LOGIN_DOMAIN           = 'https://wmscorp.apcfss.in';

/* HERB Modules UI URLS IN DEVELOP */

export const APGLI_UI_URL               = 'https://apgli.test.nidhi.apcfss.in';

export const BUDGET_UI_URL              = 'https://budget.test.herb.apcfss.in';

export const BUDGET_PLANNING_UI_URL     = "https://budgetplanning.test.herb.apcfss.in"

export const DCM_UI_URL                 = "https://cdm.test.nidhi.apcfss.in"

export const ESR_UI_URL                 = "https://esr.test.herb.apcfss.in"

export const ESS_UI_URL                 = "https://ess.test.herb.apcfss.in"

export const EXPENDITURE_UI_URL         = 'https://expenditures.test.herb.apcfss.in';

export const HRMS_UI_URL                = 'https://hrms.test.herb.apcfss.in';

export const PAYROLL_UI_URL             = 'https://payroll.test.herb.apcfss.in';

export const WORKS_UI_URL               = 'https://works.wmscorp.apcfss.in'
// export const WORKS_UI_URL               = 'http://localhost:3000'


/* Other COMMON  URLS */
export const CITIZEN_BILL_STATUS_URL    = EXPENDITURE_UI_URL + '/citizenbillstatus';

export const CHANGE_PASSWORD_UI_URL     = PAYROLL_UI_URL + "/changePassword";

export const SRConfirmation             = PAYROLL_UI_URL + "/srConfirmation";

export const TIMEZONE_REDIRECTION_URL   = "https://support.yondo.com/hc/en-us/articles/206597867-How-do-I-change-my-computer-time-zone-settings-"

export const AUTHENTICATION_URL         = LOGIN_API_BASE_URL + "authenticate";

// export const USER_VERIFICATION          = LOGIN_API_BASE_URL + "getAadharOTPByLogin/";

export const USER_VERIFICATION_OTP      = LOGIN_API_BASE_URL+ "getAadharOTPByLoginId";

export const LOGIN_AUTHENTICATION_URL   = LOGIN_API_BASE_URL + "loginOTPVerification";

export const SIGN_IN_URL                = LOGIN_API_BASE_URL + 'authenticator'

export const TOKEN_INVALIDATE_URL       = LOGIN_API_BASE_URL + 'logout'

export const GENERATE_OTP_URL           = LOGIN_API_BASE_URL + "generateotp"

export const VERIFY_OTP_URL             = LOGIN_API_BASE_URL + "verifyotp";

export const SERVICES_URL               = MASTER_API_BASE_URL + "serviceslist/";

export const EMPLOYEE_COUNT             = PAYROLL_API_BASE_URL + "srcertification/get/employeescount"

export const GET_DETAILS_BY_AADHAR_DOB  = LOGIN_API_BASE_URL+"getLoginDetails/"

export const GET_RETIRING_EMP_COUNT     = PAYROLL_API_BASE_URL+"getRetiringEmpsCountInDDo"

/*----------------------------------------------- --- TEST URLS END ------------------------------------------------------------------------------------- */

