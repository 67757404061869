import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
const Loader=()=>
{
    
        const stateObject=useSelector(state=>state.spinner);
    return(
        <div>
  {stateObject.showSpinner&&
<div className="fp-container">
            <Spinner animation="border"  size="500"   variant="info" className="fp-loader" /> 
        </div>

  }
  </div>
    )
}

export default Loader;